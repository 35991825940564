import { Avatar, Button, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SquareIcon from '@mui/icons-material/Square';
import { useState } from 'react';
import ImageDialog from '../Components/ImageDialog';
import ColorDialog from '../Components/ColorDialog';
import { getDefultItemImage } from '../../../../assets/DefultItemImage';
import { getHexColor } from '../../../hepler';

import APIErorr from '../../../Components/APIErorr';


import 'react-image-crop/dist/ReactCrop.css'
import { useDispatch, useSelector } from 'react-redux';
import { addEditNoteAndExtraSettings, reset as restAddEditNoteAndExtraSettings } from '../../../../redux/slice/Settings/NoteAndExtra/addEditNoteAndExtraSettingSlices';
import Progress from '../../../Components/Progress';
import SuccessMsg from '../../../Components/SuccessMsg';

import { t } from 'i18next';
import { getAllOnlineSetting,reset as restOnline } from '../../../../redux/slice/Settings/online/getAllIOnlineSettingSlices';

import { getRecipesByNoteAndExtraIdSetting,reset as restRecipes} from '../../../../redux/slice/Settings/NoteAndExtra/getRecipesByNoteAndExtraIdSettingSlices';





const getAvatar = (color, name, image, isNoteAndExtra) => {



    if (color === 0) {

        if (isNoteAndExtra) {

            return <Avatar variant={isNoteAndExtra ? 'circular' : 'square'} alt={name} src={`data:image/jpeg;base64,${image}`} sx={{ width: isNoteAndExtra ? 25 : 150, height: isNoteAndExtra ? 25 : 100 }} />

        }

        return <img alt={name} src={`data:image/jpeg;base64,${image}`} style={{ width: isNoteAndExtra ? 25 : 150, height: isNoteAndExtra ? 25 : 100 }} />


    }


    if (color !== null) {



        return <Avatar variant={isNoteAndExtra ? 'circular' : 'square'} alt={name} sx={{ width: isNoteAndExtra ? 25 : 150, height: isNoteAndExtra ? 25 : 100, bgcolor: getHexColor(color), marginLeft: isNoteAndExtra && 1, marginRight: isNoteAndExtra && 1 }} > </Avatar>


    }

    return <Avatar variant={isNoteAndExtra ? 'circular' : 'square'} alt={name} sx={{ width: isNoteAndExtra ? 25 : 150, height: isNoteAndExtra ? 25 : 100, bgcolor: "rgb(231, 67, 182)", marginLeft: 1, marginRight: 1 }}  > <SquareIcon /> </Avatar>


}



function AddEditNoteAndExtra(props) {

    const [setsaveNoteAndExtraFun, handleClose] = props.control || [null, null];
    const NoteAndExtraData = props.NoteAndExtra
    const [searchParams] = useSearchParams();
    const [showsuccessMsg, setShowsuccessMsg] = useState(false);

    const shopId = searchParams.get("ShopId")
    const [showAlertMsg, setShowAlertMsg] = useState({

        textName: false
    })

    const navigate = useNavigate();

    const dispatch = useDispatch()
    const addEditNoteAndExtraSettingReduser = useSelector((state) => state.addEditNoteAndExtraSettingReduser);
    const onlineSettingsReduser = useSelector((state) => state.getAllOnlineSettingsReduser);
    const recipesByNoteAndExtraIdReduser = useSelector((state) => state.getRecipesByNoteAndExtraIdSettingReduser);



    const [openImageDialog, setOpenImageDialog] = useState(false)
    const [openColorDialog, setOpenColorDialog] = useState(false)

    const [object, setObject] = useState(NoteAndExtraData === null ?

        {
            "id": 0,
            "name": "",
            "tybe": null,
            "image": "",
            "color": -11419154,
            "priceTakeAway": 0,
            "priceTable": 0,
            "priceDelivery": 0,
            "priceOnDial": 0,
            "priceOnline": 0,
            "priceDriveThru": 0,
            "noteOnlinePriceEntities":[],
            "cost":0,
            "recipes" : []


        } : NoteAndExtraData


    )






    const handelSaveNoteAndExtra = () => async (dispatch) => {

        if (object.name === '') {
            setShowAlertMsg({ ...showAlertMsg, textName: true })


            return;
        }


        setShowsuccessMsg(false)
       await dispatch(addEditNoteAndExtraSettings({ shopId, object }))


    }




    useEffect(() => {
        return () => {
            (async () => {
                await dispatch(restOnline());
                dispatch(restRecipes());
            })();
        };
    }, []);
    



    



  




    useEffect(() => {



        if (addEditNoteAndExtraSettingReduser.loading === "succeeded") {
            if (object.id !== 0) {


                if (handleClose !== null) {
                    handleClose(true)

                } else {
                    //// for mobile window size save back to item page

                    handelCancelButton();


                }



            } else {




                setShowsuccessMsg(true)

                setObject({

                    "id": 0,
                    "name": "",
                    "tybe": null,
                    "image": "",
                    "color": -11419154,
                    "priceTakeAway": 0,
                    "priceTable": 0,
                    "priceDelivery": 0,
                    "priceOnDial": 0,
                    "priceOnline": 0,
                    "priceDriveThru": 0,
                    "noteOnlinePriceEntities":[],
                    "cost":0,
                    "recipes" : []

                })







            }

             dispatch(restAddEditNoteAndExtraSettings())


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addEditNoteAndExtraSettingReduser])






   


    useEffect(() => {


        if (onlineSettingsReduser.loading === "idle") {
            dispatch(getAllOnlineSetting({ shopId }));
        }

        if (onlineSettingsReduser.loading === "succeeded") {
            if (!object.noteOnlinePriceEntities || object.noteOnlinePriceEntities.length === 0) {
                if (Array.isArray(onlineSettingsReduser.response)) {
                    const updatedItems = onlineSettingsReduser.response.map(online => ({
                        delete: false,
                        id: 0,
                        online,
                        onlineId: online.id,
                        price: 0
                    }));
                    
                    setObject(prevState => ({
                        ...prevState,
                        noteOnlinePriceEntities: updatedItems
                    }));
                }
            }


            
            if (object.noteOnlinePriceEntities && object.noteOnlinePriceEntities.length > 0) {
                if (Array.isArray(onlineSettingsReduser.response)) {
                    const updatedItems = object.noteOnlinePriceEntities.map(entity => {
                        const onlineSetting = onlineSettingsReduser.response.find(response => response.id === entity.onlineId);
                        if (onlineSetting) {
                            return { ...entity, online: onlineSetting }; // Update existing entity
                        }
                        return entity; // Return the entity unchanged if no match is found
                    });
    
                    // Find and add new online settings not already in itemsOnlinePriceEntities
                    const newOnlineSettings = onlineSettingsReduser.response.filter(onlineSetting => {
                        return !object.noteOnlinePriceEntities.some(entity => entity.onlineId === onlineSetting.id);
                    }).map(onlineSetting => ({
                        delete: false,
                        id: 0,
                        online: onlineSetting,
                        onlineId: onlineSetting.id,
                        price: 0
                    }));
    
                    // Update the state with both updated items and new additions
                    setObject(prevState => ({
                        ...prevState,
                        noteOnlinePriceEntities: [...updatedItems, ...newOnlineSettings]
                    }));
                }
            }
        
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onlineSettingsReduser.loading,  dispatch, shopId]);





    useEffect(() => {


        if (recipesByNoteAndExtraIdReduser.loading === "idle" && object.id !== 0) {
            dispatch(getRecipesByNoteAndExtraIdSetting({noteAndExtraId:object.id, shopId }));
        }

        if (recipesByNoteAndExtraIdReduser.loading === "succeeded") {
           
                    
                    setObject(prevState => ({
                        ...prevState,
                        recipes: recipesByNoteAndExtraIdReduser.response
                    }));
               
        
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipesByNoteAndExtraIdReduser.loading,  dispatch, shopId]);




    useEffect(() => {
        ///For desktop window size only

        if (setsaveNoteAndExtraFun != null) {

            setsaveNoteAndExtraFun(() => () => dispatch(handelSaveNoteAndExtra()))

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [object, setsaveNoteAndExtraFun])





    ///For Mobile window size only
    const handelSaveButton = () => {
        dispatch(handelSaveNoteAndExtra());


    }


    ///For Mobile window size only

    const handelCancelButton = () => {
        navigate({

            pathname: "/Dashboard/Settings/NoteAndExtra",
            search: "?" + searchParams.toString()


        })

    }





    const onChangePicture = e => {
        if (e.target.files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setObject({ ...object, color: 0, imageTemp: reader.result });

                setOpenImageDialog(true);


            });



            reader.readAsDataURL(e.target.files[0]);
        }
    };
    const handleChange = (event) => {

        const { name, value, checked, type } = event.target;
        let newValue = value;


        if (event.target.name.startsWith("price")){

            if (value === '') {
                newValue = 0
            }


        }
        

        if (name === "priceTakeAway") {


            
            const updatedItems=    object.noteOnlinePriceEntities.map(item => {
            
                if (item.price ===  0 || item.price === object.priceTakeAway  ) {
                    return { ...item, price: newValue };
                } 

                return item;
            })


          

            setObject({
                ...object, "priceTakeAway": newValue,
                "priceTable": object.priceTable === 0 || object.priceTable === object.priceTakeAway ? newValue : object.priceTable,
                "priceDelivery": object.priceDelivery === 0 || object.priceDelivery === object.priceTakeAway ? newValue : object.priceDelivery,
                "priceOnDial": object.priceOnDial === 0 || object.priceOnDial === object.priceTakeAway ? newValue : object.priceOnDial,
                "priceOnline": object.priceOnline === 0 || object.priceOnline === object.priceTakeAway ? newValue : object.priceOnline,
                "priceDriveThru": object.priceDriveThru === 0 || object.priceDriveThru === object.priceTakeAway ? newValue : object.priceDriveThru,
               "noteOnlinePriceEntities": updatedItems
            });



            return;
        }



        if (name.startsWith("priceOnline-")) {
            const id = name.split('-')[1];
            const updatedItems=    object.noteOnlinePriceEntities.map(item => {
            

                if (item.onlineId === Number(id)) {
                    return { ...item, price: newValue };
                }

                return item;
            })


            setObject({ ...object, noteOnlinePriceEntities: updatedItems});

            return;

        }
        




        if (event.target.name === "buttons-group-imageType") {


            if (value === 'color') {

                setObject({ ...object, color: object.color === 0 ? -11419154 : object.color });



            } else {


                setObject({ ...object, color: 0, image: object.image === '' || object.image === null ? getDefultItemImage : object.image });


            }


            return
        }

        if (name === "name" && value !== '') {

            setShowAlertMsg({ ...showAlertMsg, textName: false })


        }




        setObject({ ...object, [event.target.name]: value });


    };








    const isLoading = addEditNoteAndExtraSettingReduser.loading === 'pending' || onlineSettingsReduser.loading === 'pending' || recipesByNoteAndExtraIdReduser.loading === 'pending';
    const hasAddEditError = addEditNoteAndExtraSettingReduser.loading === 'failed';
    const hasOnlineSettingsError = onlineSettingsReduser.loading === 'failed';
    const hasRecipesByNoteAndExtraIdError =  recipesByNoteAndExtraIdReduser.loading === 'failed';

    return (
        <Box component='form' sx={{ margin: setsaveNoteAndExtraFun === null && 2 }}>


            {showsuccessMsg && <SuccessMsg msg={t("MSG3")} />}



            {isLoading && <Progress />}
            {hasAddEditError && <APIErorr reduser={addEditNoteAndExtraSettingReduser} />}
            {hasOnlineSettingsError && <APIErorr reduser={onlineSettingsReduser} />}
            {hasRecipesByNoteAndExtraIdError && <APIErorr reduser={recipesByNoteAndExtraIdReduser} /> }




            {!isLoading && !hasOnlineSettingsError && !hasRecipesByNoteAndExtraIdError && (<>

            <TextField disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'} error={showAlertMsg.textName} helperText={object.name === '' && t("MSG8")} onChange={handleChange} value={object.name} name="name" inputProps={{ size: 15, maxLength: "15", required: true }} id="name" sx={{ margin: 1 }} fullWidth label={t("NoteAndExtraName")} variant="standard" type="text" />
            <Divider sx={{ margin: 2 }} />


            <Box disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'} sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignContent: "start", flexFlow: "wrap", marginTop: 2 }}>
                <TextField disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'} onChange={handleChange} value={object.priceTakeAway} id="priceTakeAway" name="priceTakeAway" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "25%" }} label={t("PriceTakeAway")} variant="standard" />
                <TextField disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'} onChange={handleChange} value={object.priceTable} id="priceTable" name="priceTable" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "25%" }} label={t("PriceTables")} variant="standard" />
                <TextField disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'} onChange={handleChange} value={object.priceOnDial} id="priceOnDial" name="priceOnDial" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "25%" }} label={t("PriceOnDial")} variant="standard" />
                <TextField disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'} onChange={handleChange} value={object.priceDelivery} id="priceDelivery" name="priceDelivery" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "25%" }} label={t("PriceDelivery")} variant="standard" />
                <TextField disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'} onChange={handleChange} value={object.priceDriveThru} id="priceDriveThru" name="priceDriveThru" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "25%" }} label={t("PriceDrive-Throw")} variant="standard" />
                <TextField disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'} onChange={handleChange} value={object.priceOnline} id="priceOnline" name="priceOnline" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "25%" }} label={t("PriceOnline")} variant="standard" />

                
                {object.noteOnlinePriceEntities?.map(item => (
                <TextField
                    key={item.onlineId} // Ensure each TextField has a unique key
                    disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'}
                    
                    onChange={event => handleChange(event, item.onlineId)}
                    value={item.price || 0}
                    id={`priceOnline-${item.onlineId}`}
                    name={`priceOnline-${item.onlineId}`}
                    type="number"
                    inputProps={{ type: "number", step: "0.01" }}
                    sx={{ margin: 1, width: "25%" }}
                    label={item?.online?.name}
                    variant="standard"
                />
            ))}



            </Box>




            <Divider sx={{ margin: 2 }} />
                <TextField disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'} onChange={handleChange} value={object.cost} id="cost" name="cost" type="number" inputProps={{ type: "number", step: "0.01" }} sx={{ margin: 1, width: "100%" }} label={t("ItemCost")} variant="standard" />

         




            <FormControl disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'} fullWidth>
                <FormLabel id="demo-row-radio-buttons-group-label">{t("ImageType")}</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="buttons-group-imageType"
                    value={object.color === 0 ? "image" : "color"}
                    onChange={handleChange}
                >
                    <FormControlLabel value="color" control={<Radio />} label={t("Color")} />
                    <FormControlLabel value="image" control={<Radio />} label={t("Image")} />

                </RadioGroup>
            </FormControl>

            <Box sx={{ width: 150, hidden: 100 }}>




                {getAvatar(object.color, object.name, object.image, false)}
                <ImageDialog control={[openImageDialog, setOpenImageDialog]} data={[object, setObject]} />
                <ColorDialog control={[openColorDialog, setOpenColorDialog]} data={[object, setObject]} />

                <Button
                    disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'}
                    component="label"
                    onClick={() => setOpenColorDialog(true)}
                    variant="contained" sx={{ padding: "4px", height: "100%", display: object.color === 0 && 'none' }} >{t("SelectColor")}
                </Button>

                <Button
                    disabled={addEditNoteAndExtraSettingReduser.loading === 'pending'}
                    component="label"
                    variant="contained" sx={{ padding: "4px", height: "100%", display: object.color !== 0 && 'none' }} >
                    {t("UploadImage")}
                    <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        hidden
                        onChange={onChangePicture}
                        name="contentPath"

                    />

                </Button>




            </Box>
            <Box sx={{ display: setsaveNoteAndExtraFun !== null && 'none' }}>

                <Divider sx={{ margin: 2 }} />

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 1 }}>
                    <Button variant='text' onClick={handelSaveButton}>{t("Save")}</Button>
                    <Button variant='text' onClick={handelCancelButton}>{t("Cancel")}</Button>




                </Box>


            </Box>

            </>)}
          

        </Box>
    )
}

export default AddEditNoteAndExtra