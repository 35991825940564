import { combineReducers, configureStore } from '@reduxjs/toolkit'


import userReduser from './slice/User/UserSlice'; 
import accountReduser from './slice/Account/AccountSlice'; 
import mainReportsReduser from './slice/Reports/mainReportsSlice'
import fullReportByDateReduser from './slice/Reports/fullReportsSlices'
import  itemsSoldReportsReduser from  './slice/Reports/itemsSoldSlice'
import  invoicesReportsReduser from  './slice/Reports/invoicesReportsSlices'
import storeLogsReportsReduser from  './slice/Reports/storeLogsReportsSlice'
import getAllItemsSettingReduser from './slice/Settings/Items/getAllItemsSettingSlices'
import getAllCategorySettingReduser from './slice/Settings/Category/getAllCategorySettingSlices'
import addEditItemSettingReduser from './slice/Settings/Items/addEditItemSettingSlices'
import deleteItemSettingReduser from './slice/Settings/Items/deleteItemSettingSlices'
import categoryPagesSettingReduser from './slice/Settings/Category/getAllCategoryPagesSettingSlices'
import addEditCategorySettingReduser from './slice/Settings/Category/addEditCategorySettingSlices'
import deleteCategorySettingReduser from './slice/Settings/Category/deleteCategorySettingSlices'
import partnerCardPagesSettingReduser from './slice/Settings/PartnerCard/getAllPartnerCardPagesSettingSlices'
import addEditPartnerCardSettingReduser from './slice/Settings/PartnerCard/addEditPartnerCardSettingSlices'
import deletePartnerCardSettingReduser from './slice/Settings/PartnerCard/deletePartnerCardSettingSlices'


import noteAndExtraSettingReduser from './slice/Settings/NoteAndExtra/getAllNoteAndExtraSettingSlices'
import addEditNoteAndExtraSettingReduser from './slice/Settings/NoteAndExtra/addEditNoteAndExtraSettingSlices'
import deleteNoteAndExtraSettingReduser from './slice/Settings/NoteAndExtra/deleteNoteAndExtraSettingSlices'
import getAllMandatoryItemsSettingReduser from './slice/Settings/MandatoryItems/getAllMandatoryItemsSettingSlices'
import saveMandatoryItemsSettingReduser from './slice/Settings/MandatoryItems/saveAllMandatoryItemsSettingSlices'
import getAllKitchenMonitorSettingReduser from './slice/Settings/KitchenMonitor/getAllKitchenMonitorSettingSlices'
import saveKitchenMonitoSettingReduser from './slice/Settings/KitchenMonitor/saveKitchenMonitorSettingSlices'
import userPermissionsSettingReduser from './slice/Settings/UserPermissions/getAllUserPermissionsSettingSlices'
import deleteUserPermissionsSettingReduser from './slice/Settings/UserPermissions/deleteUserPermissionsSettingSlices'
import addEditUserPermissionsSettingReduser from './slice/Settings/UserPermissions/addEditUserPermissionsSettingSlices'
import describeReasonMessagesSettingReduser from './slice/Settings/DescribeReasonMessages/getAllDescribeReasonMessagesSettingSlices'
import deleteDescribeReasonMessagesSettingReduser from './slice/Settings/DescribeReasonMessages/deleteDescribeReasonMessagesSettingSlices'
import addEditDescribeReasonMessagesSettingReduser from './slice/Settings/DescribeReasonMessages/addEditDescribeReasonMessagesSettingSlices'
import getAllStoreSettingReduser from './slice/Settings/Store/getAllStoreSettingSlices'
import saveStoreSettingReduser from './slice/Settings/Store/saveStoreSettingSlices'
import saveMovementReduser from './slice/Warehouse/saveMovementSlices'
import  getAllOnlineSettingsReduser  from './slice/Settings/online/getAllIOnlineSettingSlices';
import getRecipesByItemIdSettingReduser from './slice/Settings/Items/getRecipesByItemIdSettingSlices' 
import getRecipesByNoteAndExtraIdSettingReduser from './slice/Settings/NoteAndExtra/getRecipesByNoteAndExtraIdSettingSlices' 



const combinedReducer = combineReducers({
  userReduser ,
  accountReduser ,
  mainReportsReduser , 
  fullReportByDateReduser ,
  itemsSoldReportsReduser ,
  invoicesReportsReduser , 
  storeLogsReportsReduser ,
  getAllItemsSettingReduser ,
  getAllCategorySettingReduser,
  addEditItemSettingReduser , 
  deleteItemSettingReduser , 
  categoryPagesSettingReduser ,
  addEditCategorySettingReduser ,
  deleteCategorySettingReduser ,
  noteAndExtraSettingReduser ,
  addEditNoteAndExtraSettingReduser ,
  deleteNoteAndExtraSettingReduser ,
  getAllMandatoryItemsSettingReduser ,
  saveMandatoryItemsSettingReduser , 
  getAllKitchenMonitorSettingReduser ,
  saveKitchenMonitoSettingReduser, 
  userPermissionsSettingReduser , 
  deleteUserPermissionsSettingReduser, 
  addEditUserPermissionsSettingReduser,
  describeReasonMessagesSettingReduser,
  deleteDescribeReasonMessagesSettingReduser,
  addEditDescribeReasonMessagesSettingReduser , 
  getAllStoreSettingReduser ,
  saveStoreSettingReduser ,
  partnerCardPagesSettingReduser, 
  addEditPartnerCardSettingReduser ,
  deletePartnerCardSettingReduser ,
  saveMovementReduser,
  getAllOnlineSettingsReduser,
  getRecipesByItemIdSettingReduser,
  getRecipesByNoteAndExtraIdSettingReduser
});

const rootReducer = (state, action) => {

console.log("action.type =" ,action.type);


  if (action.type === 'user/Logout/fulfilled') {
    state = undefined
  }
  return combinedReducer(state, action);
};




export const store = configureStore({
  reducer: rootReducer,

})


export default store 